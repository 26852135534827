exports.components = {
  "component---src-pages-docs-commands-js": () => import("./../../../src/pages/docs/commands.js" /* webpackChunkName: "component---src-pages-docs-commands-js" */),
  "component---src-pages-docs-faq-js": () => import("./../../../src/pages/docs/faq.js" /* webpackChunkName: "component---src-pages-docs-faq-js" */),
  "component---src-pages-docs-installation-js": () => import("./../../../src/pages/docs/installation.js" /* webpackChunkName: "component---src-pages-docs-installation-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-docs-quickstart-js": () => import("./../../../src/pages/docs/quickstart.js" /* webpackChunkName: "component---src-pages-docs-quickstart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */)
}

